// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
  font-size: 100%;
}

body {
  font-family: "Inter", sans-serif !important;
  background-color: #f7f7f7;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[multiple]:focus,
textarea:focus,
select:focus {
  border-width: 2px;
  border-color: #000000;
  border-style: solid;
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,2CAA2C;EAC3C,yBAAyB;AAC3B;;AAEA;;;;;;;;;;;;;;;EAeE,iBAAiB;EACjB,qBAAqB;EACrB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":["html {\n  font-size: 100%;\n}\n\nbody {\n  font-family: \"Inter\", sans-serif !important;\n  background-color: #f7f7f7;\n}\n\ninput[type=\"text\"]:focus,\ninput[type=\"email\"]:focus,\ninput[type=\"url\"]:focus,\ninput[type=\"password\"]:focus,\ninput[type=\"number\"]:focus,\ninput[type=\"date\"]:focus,\ninput[type=\"datetime-local\"]:focus,\ninput[type=\"month\"]:focus,\ninput[type=\"search\"]:focus,\ninput[type=\"tel\"]:focus,\ninput[type=\"time\"]:focus,\ninput[type=\"week\"]:focus,\ninput[multiple]:focus,\ntextarea:focus,\nselect:focus {\n  border-width: 2px;\n  border-color: #000000;\n  border-style: solid;\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
